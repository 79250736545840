<script setup>
import { computed } from 'vue'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, ChevronDownIcon } from '@heroicons/vue/20/solid'
import { useModelWrapper } from '@/Shared/Composables/useModelWrapper.js'

const props = defineProps({
  name: {
    type: String,
    default: 'custom-select',
  },
  placeholder: {
    type: [String, Number],
    default: 'Select',
  },
  labelKey: {
    type: String,
    default: null,
  },
  valueKey: {
    type: String,
    default: null,
  },
  items: {
    type: Array,
    default: () => [],
  },
  modelValue: {
    type: [Number, Boolean, String, Object],
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  classes: {
    type: String,
    default:
      'relative w-full bg-white border border-black rounded-md shadow-sm pr-10 py-2 text-left cursor-default outline-none focus:border-2 focus:ring focus:ring-yellow-500 disabled:bg-gray-100',
  },
})

const emits = defineEmits(['update:modelValue'])

const selectedModel = useModelWrapper(props, emits)

const selectedItem = computed(() => {
  if (selectedModel.value === null || selectedModel.value === undefined) {
    return null
  }

  if (props.valueKey) {
    return props.items.find((item) => item[props.valueKey] === selectedModel.value)
  }

  return selectedModel.value
})
</script>

<template>
  <Listbox
    v-slot="{ open }"
    v-model="selectedModel"
    as="div"
    :name="name"
    :disabled="disabled"
  >
    <div class="mt-1 relative">
      <ListboxButton :class="classes">
        <slot
          name="selected"
          :selected="selectedItem"
        >
          <span class="ml-3 block truncate">
            {{
              labelKey && selectedModel !== undefined && selectedModel !== null ? selectedItem[labelKey] : selectedModel
            }}
          </span>
        </slot>
        <span class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <ChevronDownIcon
            :class="[open ? 'rotate-180' : '', 'h-6 w-6 text-secondary transition ease-in-out']"
            aria-hidden="true"
          />
        </span>
        <span
          v-if="selectedModel === undefined || selectedModel === null"
          class="ml-3 block truncate"
        >
          {{ placeholder }}
        </span>
      </ListboxButton>

      <Transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute z-50 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
        >
          <ListboxOption
            v-for="(item, index) in items"
            :key="item.id ? item.id : index"
            v-slot="{ active, selected }"
            as="template"
            :value="valueKey ? item[valueKey] : item"
          >
            <li :class="[active ? 'bg-secondary-50' : 'text-gray-900', 'cursor-pointer select-none relative py-2 ']">
              <div class="flex items-center">
                <slot
                  :item="item"
                  :active="active"
                  :selected="selected"
                >
                  <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">
                    {{ labelKey ? item[labelKey] : item }}
                  </span>
                </slot>
              </div>

              <span
                v-if="selected"
                :class="['absolute inset-y-0 right-0 flex items-center pr-4 text-secondary-700']"
              >
                <CheckIcon
                  class="h-5 w-5 text-secondary"
                  aria-hidden="true"
                />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </Transition>
    </div>
  </Listbox>
</template>
